<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <bullet :canBack="true" colorIcon="#052633" title="Detail Event" />
      <h3 class="mt-5 mb-2 px-2">Info Layanan</h3>
      <v-row v-if="!loading && dataSet" no-gutters class="pb-2">
        <v-col cols="12" md="8" class="px-2 mt-2">
          <div
            class="rounded"
            style="overflow: hidden; border: 0.05em solid #cdd0d1"
          >
            <v-row no-gutters>
              <v-col cols="4">
                <v-card
                  height="100%"
                  flat
                  rounded="0"
                  style=""
                  class="pa-3"
                  color="#CDD0D1"
                >
                  Nama Klien
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  height="100%"
                  flat
                  rounded="0"
                  style="height: 100%"
                  class="pa-3 text-capitalize"
                >
                  {{ dataSet.customer }}
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1">
              <v-col cols="4">
                <v-card
                  flat
                  height="100%"
                  rounded="0"
                  class="pa-3"
                  color="#CDD0D1"
                >
                  Tujuan Event
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card flat height="100%" rounded="0" class="pa-3">
                  {{ dataSet.tujuan_event }}
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1">
              <v-col cols="4">
                <v-card
                  flat
                  height="100%"
                  rounded="0"
                  class="pa-3"
                  color="#CDD0D1"
                >
                  Tipe Klien
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  flat
                  height="100%"
                  rounded="0"
                  class="pa-3 text-capitalize"
                >
                  {{ dataSet.tipe }}
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1">
              <v-col cols="4">
                <v-card
                  height="100%"
                  flat
                  rounded="0"
                  class="pa-3"
                  color="#CDD0D1"
                >
                  Layanan
                </v-card>
              </v-col>
              <v-col cols="8">
                <div class="d-flex align-center">
                  <v-card height="100%" flat rounded="0" class="pa-3">
                    {{ dataSet.layanan }}
                  </v-card>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="dataSet.layanan == 'Tes Psikologi'"
                    text
                    depressed
                    class="text-capitalize mr-3"
                    @click="toFile('https://dev-dashboard.anargya.id/')"
                    style="text-decoration-line: underline"
                  >
                    Link Test</v-btn
                  >
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1">
              <v-col cols="4">
                <v-card
                  flat
                  height="100%"
                  rounded="0"
                  class="pa-3"
                  color="#CDD0D1"
                >
                  Detail Layanan
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card height="100%" flat rounded="0" class="pa-3">
                  Tanggal {{ dataSet.timeline[0].start_date }} to
                  {{
                    dataSet.timeline[dataSet.timeline.length - 1].start_date
                  }}, pukul {{ dataSet.timeline[0].start_time }} s/d
                  {{ dataSet.timeline[dataSet.timeline.length - 1].end_time }}
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="px-2 mt-2">
          <div
            class="rounded"
            style="overflow: hidden; border: 0.05em solid #cdd0d1"
          >
            <v-card flat rounded="0" class="pa-3" color="#CDD0D1">
              File Attachment
            </v-card>
            <div class="px-3 my-2">
              <div v-for="(item, n) in dataSet.timeline" :key="n">
                <div
                  class="mt-2 rounded d-flex align-center"
                  style="border: 0.05em solid #cdd0d1"
                  v-if="item.file"
                >
                  <div
                    class="rounded pa-2 d-flex align-center justify-center"
                    style="border-right: 0.05em solid #cdd0d1"
                  >
                    <v-icon>mdi-file</v-icon>
                  </div>
                  <div
                    class="pa-2"
                    @click="toFile(item.file)"
                    style="text-decoration-line: underline; cursor: pointer"
                  >
                    File {{ item.step }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-skeleton-loader
        v-if="loading"
        type="list-item-three-line, article"
      ></v-skeleton-loader>
      <div class="mt-5 mb-2 px-2 d-flex align-center">
        <h3>Timeline</h3>
        <v-spacer></v-spacer>
        <v-btn
          v-if="role != 'USER' && role != 'SUPERADMIN' && role != 'ASSOCIATE'"
          :disabled="non_aktif_id ? false : true"
          depressed
          class="bg_color1 text-capitalize"
          @click="updateData(dataSet)"
        >
          <v-icon class="mr-2" color="white">mdi-pencil</v-icon>
          <p class="txt_white ma-0">Edit Status</p>
        </v-btn>
      </div>
      <div class="mx-2 rounded" style="border: 0.05em solid #cdd0d1">
        <v-stepper elevation="0" alt-labels v-if="dataSet && !loading">
          <div
            id="scroll-target"
            class="overflow-y-auto"
            style="max-width: 100%"
          >
            <v-stepper-header class="pa-3" v-scroll:#scroll-target="onScroll">
              <div class="d-flex align-center justify-center full_w">
                <template v-for="(item, n) in dataSet.timeline">
                  <v-stepper-step
                    color="#052633"
                    :key="n"
                    :step="n + 1"
                    :complete="n <= idx"
                    :complete-icon="`mdi-numeric-${n + 1}`"
                  >
                    {{ item.step }}
                  </v-stepper-step>
                  <v-divider
                    class="bg_color1"
                    :key="`divider${n}`"
                    v-if="n < dataSet.timeline.length - 1"
                  />
                </template>
              </div>
            </v-stepper-header>
          </div>
        </v-stepper>
        <v-skeleton-loader v-if="loading" type="article"></v-skeleton-loader>
      </div>
    </div>

    <div class="pa-2">
      <dialog-medium
        :d_medium="d_create"
        :type="d_type"
        @close="d_create = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { bullet, NavbarProfile },
  computed: {
    ...mapState({
      role: (state) => state.role,
    }),
  },
  nama: "detailEvent",
  data() {
    return {
      menuLimit: false,
      offsetTop: 0,
      loading: true,
      dataSet: null,
      dataPeserta: null,
      find: "",
      d_create: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "List Event",
          disabled: false,
          href: "/admin/mo/event",
        },
        {
          text: "Detail Event",
          disabled: true,
          href: "#",
        },
      ],
      headers: [
        { text: "Nama", value: "nama_lengkap", width: "30%" },
        { text: "Instansi", value: "instansi" },
        { text: "Jabatan", value: "jabatan" },
        { text: "Kebutuhan Layanan", value: "kebutuhan_layanan" },
      ],
      non_aktif_id: null,
      idx: null,
      drag: false,
      page: 1,
      limit: 10,
      last_page: null,
      total_page: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    exportCustomer() {
      let data = {
        path: `sysadmin/registration/export-peserta`,
        body: "",
      };
      this.$store
        .dispatch("fileData/getData", data)
        .then((data) => {
          console.log(data);
          window.open(data.data.path);
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
        });
    },
    toFile(n) {
      window.open(n, "blank");
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    fetchData() {
      this.loading = true;
      this.non_aktif_id = null;
      this.idx = null;
      let data = {
        path: `sysadmin/event?filter[id]=${this.$route.params.idEvent}`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataSet = data.data[0];
          for (let i = 0; i < this.dataSet.timeline.length; i++) {
            if (this.dataSet.timeline[i].status == 0) {
              this.non_aktif_id = this.dataSet.timeline[i].id;
              this.idx = i;
              break;
            } else {
              this.idx = i;
            }
          }
          this.fetchDataPeserta();
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    fetchDataPeserta() {
      this.loading = true;
      let data = {
        path: `sysadmin/customer/list?page[size]=${this.limit}&page[number]=${this.page}&filter[nama_lengkap]=${this.find}`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataPeserta = data.data;
          this.last_page = data.last_page;
          this.total_page = data.total;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    changeLimit(val) {
      this.limit = val;
      this.fetchDataPeserta();
    },
    updateData(item) {
      let data = {
        event: item,
        data: {
          progres: this.non_aktif_id,
          idx: this.idx,
        },
      };
      this.inventory = data;
      this.d_type = "u_status_event";
      this.d_title = "Edit Status Timeline";
      this.d_create = true;
      this.line = true;
    },
  },
};
</script>

<style></style>
